interface BuildBlokkliProps {
  uuid: string
  canEdit?: boolean
}

export interface BlokkliProps {
  entityUuid: string
  entityType: string
  entityBundle: string
  canEdit: boolean
  language: string
}

export default function (
  props:
    | Partial<BuildBlokkliProps>
    | ComputedRef<Partial<BuildBlokkliProps | undefined | null> | undefined>
    | Ref<Partial<BuildBlokkliProps | undefined | null> | undefined>
    | undefined,
  entityType = 'node',
  entityBundle = 'page',
): ComputedRef<BlokkliProps> {
  const language = useCurrentLanguage()
  return computed(() => {
    return {
      entityUuid:
        (props && 'value' in props ? props.value?.uuid : props?.uuid) || '',
      entityType: entityType || 'node',
      entityBundle,
      canEdit: !!(props && 'value' in props
        ? props.value?.canEdit
        : props?.canEdit),
      language: language.value,
    }
  })
}
